
const state = {
  menu: {
    home: {
      en: 'Home',
      fr: 'Accueil'
    },
    methods: {
      en: 'Methods',
      fr: 'Méthodes'
    },
    about: {
      en: 'About',
      fr: 'À PROPOS'
    },
    contact: {
      en: 'CONTACT',
      fr: 'CONTACT'
    },
  },
  phone: "+33 7 63 04 73 21",
  email: "may@mayledan.com",
  intro: {
    en: "“Life is about being and becoming”  <br /> <span class='quote-credit'>Carl Rogers</span> ",
    fr: "Parfois un pas suffit et c’est tout un horizon qui change...",
  }  , 
  headline: {
    en: "Coping with a major life change or <span class='nobr'>relationship difficulties?</span> <br /> Struggling with worry or anxiety?<br />  Are you having difficulty finding harmony in <span class='nobr'>your relationships?</span> <br /> Do you feel overwhelmed and tired? ",
    fr: "Vous faites face à une crise de vie majeure ou à des difficultés dans vos relations ? <br /> L’anxiété et le stress vous bloquent ? <br /> Vous avez du mal à trouver l’harmonie dans votre quotidien ? <br /> Vous sentez-vous dépassé(e) et fatigué(e) ? ",
  },  

  section2: {
    subtitle: {
      en: "Coaching & Therapy",
      fr: "Coach - Thérapeute"
    },
    copy: {
      en: "<p> Maybe you’re asking yourself if you should start a psychotherapy? </p> <p> Releasing subconscious blockages that sabotage our relationships, success or self esteem, is a process best accompanied  by a qualified professional, who can help you move beyond limiting beliefs, so you can find your way back to happiness and well being. </p> <p> Trained and certified in multiple approaches, I am committed to offering you the effective support you need in order to make the changes you want in your life. <br> Together we will create a process that best works for you, in a safe place, free from judgment,  where you can feel comfortable to explore your feelings and grow. </p> <p> Are you ready to reconnect to a life of joy and harmony? </p>",
      fr: " <p>Peut-être vous vous demandez si avez besoin d'une psychothérapie ? </p> <p> Débloquer les <strong>barrières inconscientes</strong> qui sabotent nos relations, notre réussite ou notre estime de soi est un cheminement qui nécessite un accompagnement par un professionnel qualifié qui peut vous aider à aller au-delà des croyances qui bloquent <strong>votre chemin vers le bonheur et le bien-être</strong>. </p> <p> Diplômée et formée à de multiples approches, je m'engage à vous apporter le soutien nécessaire pour effectuer les changements dont vous avez besoin pour avancer dans votre vie. </p> <p> Ensemble, nous créerons la procédure qui fonctionnera le mieux pour vous, dans <strong>un cadre libre de tout jugement</strong> et où vous vous sentirez à l'aise pour vous exprimer et pour explorer vos sentiments. </p> <p> Êtes-vous prêt(e) à <strong>vous reconnecter à une vie heureuse et harmonieuse</strong> ?</p> "
    },
    cta: {
      title: {
        en: "Take the first step: ",
        fr: "",
      },
      buttonLabel: {
        en: "let’s talk",
        fr: "CONTACTEZ-moi"
      }
    },
  },
  
  section3: {
    methods: {
      title: {
        en: "Methods",
        fr: "Méthodes "
      },
      copy: {
        en: "<p> My approach to therapy is holistic, drawing from a range of creative therapeutique technics: </p> <p> <strong>Integrative Psychotherapy</strong> (<a href='https://www.integrativetherapy.com/en/integrative-psychotherapy.php' target='_blank' rel='noopener noreferrer'>Richard Irskine</a>), <strong>Existential Psychotherapy</strong> (<a href='https://www.yalom.com/existential-psychotherapy' target='_blank' rel='noopener noreferrer'>Irvin Yalom</a>), and <strong>Emotionally Focused Therapy</strong> (<a href='https://iceeft.com/' target='_blank' rel='noopener noreferrer'>Sue Johnson</a>) </p>",
        fr: "<p> Mon approche à la thérapie est holistique, je puise dans de nombreuses techniques éprouvées: </p> <p> <strong>Psychothérapie Intégrative</strong> (<a href='https://www.integrativetherapy.com/en/integrative-psychotherapy.php' target='_blank' rel='noopener noreferrer'>Richard Irskine</a>), <strong>Psychothérapie Existentielle</strong> (<a href='https://www.yalom.com/existential-psychotherapy' target='_blank' rel='noopener noreferrer'>Irvin Yalom</a>) et <strong>Thérapie émotionnelle</strong> (<a href='https://iceeft.com/' target='_blank' rel='noopener noreferrer'>Sue Johnson</a>). </p>"
      }
    },
    briefTherapy: {
      title: {
        en: "BRIEF THERAPY",
        fr: "Thérapie brève"
      },
      copy: {
        en: "<p> Brief therapy focuses on the client’s presenting symptoms and current life circumstances, and it emphasizes the strengths and resources of the client, focusing on future achievements rather than continuing to dwell on correcting past problems. The therapist in brief therapy is active and directive. There are many approaches to brief therapy: Hypnosis, Sophrology, EMDR, just to name a few.  In addition typically, existing long-term therapies have been adapted to a short-term context. </p>",
        fr: "<p>La thérapie brève se concentre sur les symptômes et la situation actuelle du patient. Elle met l’accent sur les forces et les ressources du patient, se concentrant sur le futur plutôt que de continuer à rester sur les faits passés.  <br /> En thérapie brève, le thérapeute est actif et directif. Il y a de nombreuses approches pour la thérapie brève : hypnose, sophrologie, EMDR en sont quelques exemples. Additionnellement, des thérapies long-terme ont été adaptées aux thérapie plus courtes.</p>"
      }
    },
    hypnotherapy: {
      title: {
        en: "HYPNOTHERAPY",
        fr: "HYPNOTHÉRAPIE"
      },
      copy: {
        en: "<p> Negative core beliefs hold people captive in repetitive patterns of unproductive behaviour. In Hypnotherapy we communicate directly with the unconscious mind and release the limiting beliefs and false self-images created by trauma, abuse and emotional wounding. We heal the wounds, dissolve the emotional residue of experiences that created these false beliefs, so you can live your life on your own terms. The best thing about hypnotherapy is that it is available to almost anyone and because it works rapidly, there’s no need for years of sessions. </p>",
        fr: "<p> L'hypnose Ericksonienne fait partie des thérapies dites brèves. C'est une méthode qui vise à modifier légèrement l'état de conscience afin d'accéder à l'inconscient. Son but est de vous permettre de puiser en vous les ressources nécessaires à la résolution des problèmes. <br /> L’hypnose Ericksonienne est très respectueuse des valeurs de la personne et de son environnement. C’est une approche rapide, flexible, indirecte (à travers la visualisation d’images) et non invasive. Les champs d’applications de l’hypnose thérapeutique sont très vastes et souvent surprenants. On constate que l’hypnose à des effets à plusieurs niveaux : au niveau physique, comportement et au niveau du développement personnel et émotionnel... </p>"
      }
    }, 
    stressManagement: {
      title: {
        en: "Stress management / Sophrology",
        fr: "Sophrologie"
      },
      copy: {
        en: "<p> Sophrology is a structured method created by Pr. Caycedo, to produce optimal health and well-being. It consists of a series of easy-to-do physical and mental exercises that, when practiced regularly, lead to a healthy, relaxed body and a calm, alert mind.  <br> The first things people generally notice after starting to practice sophrology are: <br /> more restful sleep, improved concentration, fewer worries, increased self-confidence, and a feeling of inner happiness. </p>",
        fr: "<p> La sophrologie est une méthode structurée créée par Pr.Caycedo, pour accéder à un état optimal de santé et de bien-être.  <br /> Elle consiste en une série d’exercices physiques et mentaux, à la portée de tous, qui, pratiquées régulièrement, vous permettent d'être reposé et calme tout en gardant un esprit vif. Les premiers effets, après une pratique de la sophrologie, sont : un sommeil plus réparateur, une meilleure concentration, moins de stress et d’anxiété. Le tout associé à une meilleure confiance en soi et un sentiment de paix intérieure. </p>"
      }
    },
    imoEmdr: {
      title: {
        en: "COACHING",
        fr: "COACHING"
      },
      copy: {
        en: "<p> Life is full of surprises and while we can not predict the outcome, we can take control of how we feel and respond to situations and people. As your personal coach, I will be your supportive partner through the process of taking inventory of your life, setting and achieving proactive, powerful goals. You will discover who you are and what really motivates you in order to create the life and career you truly want. </p>",
        fr: "<p>Nous voulons tous faire ressortir le meilleur de nous-même, acquérir de l’assurance et accroitre nos capacités. Le coaching personnalisé vous permettra de déterminer des objectifs clairs et précis et mettre en place une stratégie pour les concrétiser. Le coaching personnel est une approche de la personne dans sa globalité. L'ICF (la Fédération Internationale des Coachs) le définit comme un « accompagnement professionnalisé d'une personne dans l'élaboration, la mise en œuvre et l'évolution de son projet de vie ».</p>"
      }
    },
  },
  section4: {
    title: {
      en: "ABOUT ME",
      fr: "A propos"
    },
    copy: {
      en: "<p> My move to France from San Francisco in 1991 was a life changing event... </p> <p> Soon after, I started my studies at the European Institute of Applied Psychology where I obtained my certification as a Psychotherapist, as well as a Master Practitioner in Ericksonian Hypnosis. I continued my training to obtain a certification in Analytical Sophrology, a highly effective stress- management technique.  <br> I return to San Francisco on a regular basis to pursue advanced training at the Acredited Coaching Training Program (ACTP). Continued education in the fields of psychology and coaching allows me to explore and learn new techniques that I can pass on to my clients as well as enhacing my own personal growth. <br> </p> <p> For the past 15 years, I have had the privilege to work with individuals and couples alike to help them overcome personal challenges and work through difficult times. Having praticed in Valbonne and Cannes, I can now welcome you in my new office, located on the hills of the riviera </p>",
      fr: "<p>  Mon déménagement de San Fransisco en France, en 1991, fût un évènement, qui bouleversa ma vie... <br /> Peu après, j’ai commencé mes études à l’Institut Européen de Psychologie Appliquée ou j’ai obtenu mon diplôme de Psychothérapiste ainsi qu’un Master en Pratique d’Hypnose Ericksonienne. Poursuivant mes études, j’ai également obtenu un diplôme en Sophrologie Analytique, une méthode de management du stress très efficace. <br /> Je retourne régulièrement à San Fransisco pour affiner mes compétences en ACTP ( Acredited Coaching Training Program). Cette exploration continue me permet d’apprendre de nouvelles techiques, que je partage avec mes clients, ainsi que d’améliorer ma méthode globale. <br /> Cela fait 15 ans que j’ai le privilège de travailler avec des individus et des couples afin de les aider à surmonter leurs défis personnels et de résister aux épreuves difficiles de la vie. Ayant d’abord pratiqué à Valbonne, puis à Cannes, je peux maintenant vous accueillir dans mon nouveau bureau sur les collines de la Riviera. </p>"
    },
    copy2: {
      en: "<p> <strong>My commitment is to help you move beyond mental and emotional roadblocks, equip you with skills you will need, so you may be able create enduring, rewarding relationships and a joyful, harmonious life.</strong> </p>",
      fr: "<p> <strong>Mon engagement est d’aider les gens à passer outre les bloquages émotionnels et mentaux, en vous équipant avec les techniques vous étant les mieux adaptées. Ainsi, vous pourrez créer des relations stables et épanouissantes, au sein d’une vie harmonieuse.</strong> </p>"
    },
    banner: {
      quote: {
        en: "“When we are no longer able to change a situation, then we are challenged to change ourselves...”",
        fr: "“Les choses ne sont jamais ce qu’elles semblent. Elle deviennent toujours ce que nous en faisons....” ",
      },
      credit: {
        en: "Viktor Frankel",
        fr: "Viktor Frankel",
      }
     
    }, 
  },
  section5: {
    title: {
      en: "Where & how ?",
      fr: "où et comment ?"
    },
    subtitle: {
      en: "Although therapy is most effective in person, our first contact can be via email, telephone or video call.",
      fr: "Bien que la thérapie soit le plus efficace en face à face, notre premier contact peut se faire par email, téléphone ou appel vidéo."
    },
    box1: {
      title: {
        en: "ONLINE",
        fr: "EN LIGNE"
      },
      copy: {
        en: "In order to accomodate my international and local clientel, I offer online or phone  sessions",
        fr: "Afin d’accomoder ma clientelle locale et internationale, j’offre des sessions en lignes ou par téléphone."
      }
    },
    box2: {
      title: {
        en: "IN PERSON",
        fr: "présentiel"
      },
      copy: {
        en: "My office is located on the hills of Cannes, in a quite and peaceful environement.",
        fr: "Mon bureau se situe dans les hauteurs de Cannes, paisible et au calme..."
      }
    },
    box3: {
      title: {
        en: "AT HOME",
        fr: "A DOMICILE"
      },
      copy: {
        en: "Exceptional situations demand exceptional measures. I can come to you if needed.",
        fr: "Dans des situations exceptionnelles, je suis en mesure de me déplacer chez vous."
      }
    },
  },
  section6: {
    title: {
      en: "CONTACT ME",
      fr: "ME CONTACTER"
    },
    subtitle: {
      en: "Write me a message for more information or to make an appointment by calling",
      fr: "Ecrivez moi pour plus d’information ou pour fixer un premier rendez-vous, appelez le"
    },
    form: {
      nameLabel: {
        en: "Your name...",
        fr: "Votre nom..."
      },
      emailLabel: {
        en: "Your email address...",
        fr: "Votre adresse email..."
      },
      phoneLabel: {
        en: "Telephone number...",
        fr: "Telephone number..."
      },
      radio1: {
        en: "I would like May to call me",
        fr: "J’aimerais que vous me rappeliez",
      },
      radio2: {
        en: "I am interested in online sessions",
        fr: "Je suis intéressé(e) par des sessions en visio-conférence",
      },
      messageLabel: {
        en: "Type your message here...",
        fr: "Ecrivez votre message ici...",
      },
      submitLabel: {
        en: "SEND",
        fr: "ENVOYER",
      },
      feedbackError: {
        en: {
          title: "Somethings seems to have gone wrong...",
          copy: " Please try calling me at +33 7 63 04 73 21 or directly at <a href='mailto:may@mayledan.com' target='_blank' rel='noopener noreferrer'>May@mayledan.com</a>",
          buttonLabel: "Try again"
        },
        fr: {
          title: "Le message n'a pas abouti...",
          copy: "Essayez de me contacter au +33 7 63 04 73 21 ou directement sur <a href='mailto:may@mayledan.com' target='_blank' rel='noopener noreferrer'>May@mayledan.com</a>",
          buttonLabel: "Réessayer"
        }
      },
      feedbackSuccess: {
        en: {
          title: "Thank you for reaching out!",
          copy: " I'll do my best to come back to you as soon as possible. <br /> Have a good day."
        },
        fr: {
          title: "Merci pour votre message!",
          copy: "Je reviens vers vos le plus rapidement possible.<br /> Bonne journée."
        }
        
      },
    }
  },
  section7: {
    
      copy: {
        en: "<p> I hope you find what you need. <br> Feel free to contact me if you need further assistance. </p> <p> Thank you for your visit </p>",
        fr: "<p> J’espère sincèrement que vous trouverez ce dont vous avez besoin. N’hésitez pas à me contacter si vous avez besoin d’assistance.<br /> Merci de votre visite!</p>",
      }
    
  }
  
};

const getters = {
    GET_CONTENT: () => state,
};

const mutations = {
     
};

const actions = {
    
};

export default {
    state,
    getters,
    mutations,
    actions,
};
