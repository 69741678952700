<template>
    <div class="intro">
        <div class="anchor" id="home"></div>
        <div class="wrapper intro-content" :class="{ 'is-mounted': isMounted }">
            <h2 class="intro__heading">
                <span v-html="content.intro[lang]"></span>
            </h2>
            <h3 class="intro__subheading" v-html="content.headline[lang]"></h3>
        </div>
        <img
            src="../assets/section2-decor.svg"
            class="section2-decor"
            alt=""
            aria-hidden="true"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    data() {
        return {
            isMounted: false,
        };
    },
    mounted() {
        this.isMounted = true;
    },
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
};
</script>

<style lang="scss">
.intro {
    height: 800px;

    background: #84b3d3 url(../assets/intro__bg.png) center no-repeat;
    background-size: auto 1080px;
    color: #fff;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    height: 100vh;
    max-height: 1080px;

    @media (min-width: 768px) {
        padding-top: 100px;
    }
    @media (min-width: 1024px) {
        background: #84b3d3 url(../assets/intro__bg.png) fixed center no-repeat;
        background-size: auto 1080px;
    }
    @media (min-width: 1200px) {
        background: #84b3d3 url(/img/intro__bg.399842ac.png) fixed 70% 50%
            no-repeat;
        background-size: cover;
    }
    /*  @media (min-width: 1400px) {
        background: #84b3d3 url(../assets/intro__bg.png) fixed left bottom;
        background-size: cover;
    } */
}
.intro__heading {
    font-weight: 300;
    font-size: 26px;
    line-height: 1.1;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
    > span {
        display: inline-block;
    }
    @media (min-width: 768px) {
        font-size: 38px;
    }

    .quote-credit {
        display: block;
        font-style: italic;
        text-transform: none;
        font-size: 23px;

        @media (min-width: 768px) {
            text-align: right;
        }
    }
}

.intro__subheading {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.01em;
    color: #213f63;
    margin-top: 10px;
    padding-top: 32px;
    @media (min-width: 375px) {
        font-size: 16px;
    }
    @media (min-width: 1024px) {
        font-size: 20px;
    }
    @media (min-width: 1600px) {
        font-size: 21px;
    }
}

.header-mobile {
    position: absolute;
    text-align: center;
    padding: 12px 0;
    img {
        height: 120px;
    }
    @media (min-width: 1024px) {
        display: none;
    }
}

.intro-content {
    &.is-mounted {
        visibility: visible;
        opacity: 1;
    }
}
.section2-decor {
    position: absolute;
    bottom: -2px;
    left: 0;
    display: block;
    width: 100%;
}
</style>
