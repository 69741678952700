<template>
    <section class="section7">
        <div class="thanks">
            <div class="wrapper">
                <div v-html="content.section7.copy[lang]"></div>
            </div>
        </div>
        <div class="info">
            <div class="info__bg"></div>
            <div class="info__content">
                <img src="../assets/logo--vert.svg" alt="" class="logo" />
                <div class="contact">
                    <a
                        :href="`tel:${content.phone.trim()}`"
                        class="contact__phone"
                    >
                        {{ content.phone }}
                    </a>
                    <a :href="`mailto:${content.email}`" class="contact__email">
                        {{ content.email }}
                    </a>
                </div>
                <div class="map">
                    <div style="width: 100%">
                        <iframe
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Vallauris&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            width="100%"
                            height="600"
                            frameborder="0"
                        ></iframe
                        ><a href="https://www.maps.ie/route-planner.htm"
                            >Journey Planner</a
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <div class="contact__data">
                <a :href="`mailto:${content.email}`" class="contact__email">
                    {{ content.email }}
                </a>
                <br />
                <a :href="`tel:${content.phone.trim()}`" class="contact__phone">
                    {{ content.phone }}
                </a>
            </div>
            <div class="wrapper">
                <span class="footer__1">
                    <a
                        href="https://brandstarter.agency/"
                        target="_blank"
                        rel="noopener noreferrer "
                    >
                        WEBSITE BY <strong>Brand Starter Agency</strong>
                    </a>
                </span>
                <span class="footer__2"
                    ><strong>May ledan © 2021 all rights reserved</strong></span
                >
                <span class="footer__3">PHOTO : Jeremy Thomas</span>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
};
</script>

<style lang="scss">
.section7 {
    color: #fff;
    background: #91c1e2;
    text-align: center;

    .title {
        font-weight: 700;
        letter-spacing: 0;
        margin-bottom: 24px;
        text-shadow: 0px 6px 10px rgba(0, 0, 0, 0.36);
    }
    p {
        font-weight: 600;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
        font-size: 18px;
    }
    .thanks {
        position: relative;
        z-index: 2;
        background: url(../assets/thanks_bg.svg) no-repeat center;
        background-size: auto 100%;
        width: 100%;
        height: 353px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        p {
            margin-top: 32px;
            @media (min-width: 768px) {
                margin-top: 48px;
                font-size: 20px;
            }
        }
    }

    .info {
        position: relative;
        min-height: 400px;
        margin-top: -100px;
        padding: 100px 0 80px 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
    .info__bg {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: url(../assets/footer_pic.jpg) no-repeat center;
        background-size: cover;
    }
    .info__content {
        position: relative;
        z-index: 5;
        width: 341px;
        max-width: 80%;
        padding: 24px;
        margin: auto;
        background: rgba(255, 255, 255, 0.6);
        color: #37587d;
        text-transform: uppercase;
    }

    .logo {
        height: 125px;
        margin-bottom: 20px;
    }
    .contact {
        margin-bottom: 20px;
        font-weight: 300;
    }
    .contact__phone,
    .contact__email span {
        font-weight: 400;
    }

    .map {
        width: 205px;
        height: 155px;
        background: gray;
        margin: auto;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .footer {
        background: #0c314c;
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
        padding: 2em 0 4em 0;

        .contact__data {
            font-size: 18px;
            margin: 0 auto 24px auto;
            .contact__email {
                display: inline-block;
                letter-spacing: 0.02em;
                margin-bottom: 10px;
            }
            .contact__phone {
                display: block;
                letter-spacing: 0.15em;
            }
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            font-weight: 300;
            strong {
                font-weight: 400;
            }
            @media (max-width: 767px) {
                .footer__2 {
                    order: 0;
                    margin-bottom: 16px;
                    font-size: 1.2em;
                }
                .footer__1,
                .footer__3 {
                    order: 1;
                }
            }
        }

        @media (min-width: 768px) {
            padding: 2em 0;
            .wrapper {
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                span {
                    flex: 0 0 32%;
                    text-align: center;
                }
            }
        }
    }
}
</style>

<style lang="scss">
[data-aos='info-anm'] {
    opacity: 0;
    &.aos-animate {
        opacity: 1;
    }
}
</style>
