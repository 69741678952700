import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import VueRouter from 'vue-router'   
import router from './router'   
import VueMeta from 'vue-meta'; 

 
import { store } from './store'

import AOS from 'aos';

Vue.use(VueRouter)                  
Vue.use(Vuex) 
Vue.use(VueMeta) 


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),

  created() {
    AOS.init({
        throttleDelay: 99,
        delay: 200,
        duration: 600,
        startEvent: 'scroll',
        once: true,
        easing: "ease-in-out-sine",
        anchorPlacement: "center-bottom"
    });
    const resizeObserver = new ResizeObserver(() => {
            AOS.refreshHard(); 
        } 
    ) 
    resizeObserver.observe(document.body)   
  },
  
}).$mount('#app')
