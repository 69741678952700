<template>
    <div>
        <header class="header" :class="{ 'is-scrolled': isScrolled }">
            <div class="wrapper">
                <h1 class="header-logo">
                    <a href="/"
                        ><img
                            src="../assets/logo.png"
                            alt="May Ledan - Coache - Therapist"
                    /></a>
                </h1>

                <button
                    class="call-menu"
                    v-on:click="menuOpen = !menuOpen"
                ></button>

                <nav class="header-nav" :class="{ 'is-open': menuOpen }">
                    <div class="header-nav__col header-nav__menu">
                        <a
                            href="#home"
                            v-on:click.prevent="scrollToAnchor('home')"
                        >
                            {{ content.menu.home[lang] }}
                        </a>
                        <a
                            href="#methods"
                            v-on:click.prevent="scrollToAnchor('methods')"
                            >{{ content.menu.methods[lang] }}</a
                        >
                        <a
                            href="#about"
                            v-on:click.prevent="scrollToAnchor('about')"
                            >{{ content.menu.about[lang] }}</a
                        >
                        <a
                            href="#contact"
                            v-on:click.prevent="scrollToAnchor('contact')"
                            >{{ content.menu.contact[lang] }}</a
                        >
                    </div>
                    <div class="header-nav__col header-nav__lang">
                        <router-link
                            class="header-nav__lang__item header-nav__lang__item--en"
                            to="/#home"
                            :class="{ active: lang == 'en' }"
                        >
                        </router-link>
                        <router-link
                            class="header-nav__lang__item header-nav__lang__item--fr"
                            to="/fr#home"
                            :class="{ active: lang == 'fr' }"
                        >
                        </router-link>
                    </div>
                </nav>
            </div>
        </header>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
    data() {
        return {
            isScrolled: false,
            menuOpen: false,
        };
    },
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToAnchor(hash) {
            if (hash !== window.location.hash.split('#')[1]) {
                this.$router.push({ hash: '#' + hash });
            } else {
                this.$router.push({ hash: '' });
                this.$router.push({ hash: '#' + hash });
            }
        },

        handleScroll() {
            this.isScrolled = window.scrollY > 100;
            this.menuOpen = false;
        },
    },
};
</script>

<style lang="scss">
#app {
    height: 200vh;
}
.header {
    color: #fff;
    position: relative;
    padding-top: 21px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: #7dafd4;
    transition: all 0.2s;

    @media (min-width: 1024px) {
        height: 100px;
        width: 100%;
        top: 0;
        left: 0;
    }

    .wrapper {
        position: relative;
        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            max-width: 1200px;

            padding-bottom: 8px;
        }
    }
    @media (min-width: 1024px) {
        &::after {
            content: '';
            display: block;
            width: calc(95% - 310px);
            height: 1px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1)
            );
        }
        @media (min-width: 1333px) {
            &::after {
                width: calc(100vw - ((100vw - 1200px) / 2) - 310px);
            }
        }
    }
}
.header-logo {
    img {
        height: 50px;
        flex: 0 0 270px;
        margin-right: 40px;
        display: block;
        @media (min-width: 1024px) {
            height: 64px;
        }
    }
}

.header-nav {
    position: relative;
    flex: 1 1 auto;
    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
    }
}

.header-nav__col {
    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.header-nav__menu {
    flex: 1 1 auto;
    text-transform: uppercase;
    justify-content: space-around;
    @media (min-width: 1024px) {
        margin-right: 24px;
        a {
            font-size: 15px;
            padding: 0 20px;
        }
    }
}

.header-nav__lang__item {
    margin: auto 0 auto 8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-size: cover;
    opacity: 0.5;
    &.active {
        opacity: 1;
    }
}

.header-nav__lang__item--fr {
    background-image: url(../assets/lang/fr.png);
}
.header-nav__lang__item--en {
    background-image: url(../assets/lang/en.png);
}
@media (max-width: 767px) {
    .header {
        .wrapper {
            padding: 16px;

            width: 100%;
        }
    }
}
@media (max-width: 1023px) {
    .header {
        padding: 0;
        /*  opacity: 0;
        transform: translateY(-100%);
        transition: all 0.3s ease-out;
         opacity: 1;
            transform: translateY(0%); */
        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
    .header-nav {
        background: #fff;
        color: #374d7f;
        width: 205px;
        position: absolute;
        top: 100%;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-out;
        &.is-open {
            opacity: 1;
            visibility: visible;
        }
        .header-nav__menu {
            a {
                position: relative;
                padding: 16px;
                text-align: center;
                font-size: 15px;
                display: block;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(
                        to right,
                        rgba(55, 77, 127, 0),
                        #374d7f 53.13%,
                        rgba(55, 77, 127, 0) 100%
                    );
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
    .header-nav__lang {
        padding: 16px 0;
        display: flex;
        justify-content: center;
    }
    .header-nav__lang__item {
        width: 27px;
        height: 27px;
        margin: auto 12px;
    }

    .call-menu {
        width: 32px;
        height: 27px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='27' viewBox='0 0 32 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='5' fill='white'/%3E%3Crect y='11' width='32' height='5' fill='white'/%3E%3Crect y='22' width='32' height='5' fill='white'/%3E%3C/svg%3E%0A");
    }
}
</style>
