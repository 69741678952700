<template>
    <section>
        <div class="anchor" id="contact"></div>
        <div class="wrapper">
            <h2 class="title">{{ content.section6.title[lang] }}</h2>
            <h3>
                <div class="contact__title__phone">{{ content.phone }}</div>
            </h3>

            <div class="form-container">
                <form
                    action="#"
                    @submit.prevent="sendEmail"
                    :class="{ 'is-hidden': isFormSent }"
                >
                    <input
                        type="text"
                        name="from_name"
                        v-model="name"
                        :class="{ 'is-invalid': nameInvalid }"
                        :placeholder="content.section6.form.nameLabel[lang]"
                    />
                    <input
                        type="email"
                        name="from_email"
                        v-model="email"
                        :class="{ 'is-invalid': emailInvalid }"
                        :placeholder="content.section6.form.emailLabel[lang]"
                    />

                    <label
                        for="call_me"
                        class="checkbox"
                        :class="{ 'is-checked': call_me }"
                    >
                        <span class="input-container">
                            <input
                                type="checkbox"
                                name="call_me"
                                id="call_me"
                                value="I would like May to call me"
                                v-model="call_me"
                            />
                        </span>
                        <span>
                            {{ content.section6.form.radio1[lang] }}
                        </span>
                    </label>
                    <label
                        for="online_sessions"
                        class="checkbox"
                        :class="{ 'is-checked': online_sessions }"
                    >
                        <div class="input-container">
                            <input
                                type="checkbox"
                                name="online_sessions"
                                id="online_sessions"
                                value="I am interested in online sessions"
                                v-model="online_sessions"
                            />
                        </div>
                        <span>
                            {{ content.section6.form.radio2[lang] }}
                        </span>
                    </label>

                    <textarea
                        name="message"
                        id=""
                        v-model="message"
                        cols="30"
                        rows="10"
                        :placeholder="content.section6.form.messageLabel[lang]"
                    ></textarea>

                    <div class="recaptcha">
                        <vue-recaptcha
                            sitekey="6LdZwocaAAAAAMat-L5Sw9kjGG8u-Yysu0KvPQ0B"
                            :loadRecaptchaScript="true"
                            @verify="verifyCaptcha"
                        ></vue-recaptcha>
                    </div>

                    <button v-if="notRobot" class="btn" type="submit">
                        {{ content.section6.form.submitLabel[lang] }}
                    </button>
                </form>
                <div class="feedback" :class="{ 'is-visible': isFormSent }">
                    <div class="feedback__message">
                        <div
                            class="feedback__message--success"
                            v-if="!showFeedbackError"
                        >
                            <h3 class="title">
                                {{
                                    content.section6.form.feedbackSuccess[lang]
                                        .title
                                }}
                            </h3>
                            <p
                                v-html="
                                    content.section6.form.feedbackSuccess[lang]
                                        .copy
                                "
                            ></p>
                        </div>
                        <div
                            v-if="showFeedbackError"
                            class="feedback__message--error"
                        >
                            <p>
                                {{
                                    content.section6.form.feedbackError[lang]
                                        .title
                                }}
                            </p>
                            <p
                                v-html="
                                    content.section6.form.feedbackError[lang]
                                        .copy
                                "
                            ></p>
                            <small>
                                {{ feedbackError }}
                            </small>
                            <p>
                                <button
                                    class="btn feedback-tryAgain"
                                    v-on:click="resetForm()"
                                >
                                    {{
                                        content.section6.form.feedbackError[
                                            lang
                                        ].buttonLabel
                                    }}
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loader" :class="{ 'is-loading': isLoading }">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import emailjs from 'emailjs-com';
import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        VueRecaptcha,
    },
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    data() {
        return {
            name: null,
            nameInvalid: false,
            email: null,
            emailInvalid: false,
            call_me: false,
            online_sessions: false,
            message: null,
            isLoading: false,
            isFormSent: false,
            showFeedbackError: false,
            feedbackError: null,
            notRobot: false,
        };
    },

    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
    watch: {
        name() {
            this.nameInvalid = this.name.length == 0;
        },
        email() {
            this.emailInvalid = !this.isEmail(this.email);
        },
    },
    methods: {
        isEmail(email) {
            if (!email) return;
            return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
                email
            );
        },
        verifyCaptcha: function() {
            this.notRobot = true;
        },
        sendEmail(e) {
            if (!this.name) {
                this.nameInvalid = true;
                return;
            }
            if (!this.email) {
                this.emailInvalid = true;
                return;
            }
            if (!this.isEmail(this.email)) {
                this.emailInvalid = true;
                return;
            }

            this.isLoading = true;

            emailjs
                .sendForm(
                    'service_056evkd',
                    'template_i7s16ij',
                    e.target,
                    'user_H2ZRK0TbxhkMvxgbYsvBW'
                )
                .then(
                    () => {
                        this.isLoading = false;
                        this.isFormSent = true;
                    },
                    (error) => {
                        this.isLoading = false;
                        this.isFormSent = true;
                        this.showFeedbackError = true;
                        this.feedbackError = error.text;
                    }
                );
        },
        resetForm() {
            this.isLoading = false;
            this.isFormSent = false;
            this.showFeedbackError = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.recaptcha {
    margin: 24px 0;
    iframe {
        width: 100%;
        max-width: 100%;
    }
}
section {
    position: relative;
    padding: 80px 0;
    background: #91c1e2;
    color: #fff;
    .title {
        font-weight: normal;
    }
}
.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.37, 0, 0.63, 1);
    &.is-loading {
        opacity: 1;
        visibility: visible;
    }
}
.form-container {
    position: relative;

    form {
        opacity: 1;
        visibility: visible;
        transition: all 0.25s cubic-bezier(0.37, 0, 0.63, 1);
        &.is-hidden {
            opacity: 0;
            visibility: hidden;
        }
    }
}
.feedback {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    opacity: 0;
    visibility: hidden;
    transition: all 0.25s cubic-bezier(0.37, 0, 0.63, 1);
    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

    .feedback__message {
        width: 100%;
        color: #fff;
        padding: 36px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 1.6;
        a {
            text-decoration: underline;
        }
        .feedback__message--success::before,
        .feedback__message--error::before {
            content: '';
            width: 40px;
            height: 40px;
            display: block;
            margin: 8px auto;
            background-repeat: no-repeat;
            background-position: center;
        }
        .feedback__message--success::before {
            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
        }
        .feedback__message--error::before {
            background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23fff' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'%3E%3C/path%3E%3C/svg%3E");
        }
        .feedback__message--error {
            p,
            small {
                margin-top: 1rem;
                display: block;
                a {
                    text-decoration: underline;
                }
            }
        }

        text-align: center;
        .title {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 1em;
        }
    }
}

input,
textarea {
    display: block;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.51);
    margin-top: 24px;
    border-radius: 5px;
    border: 0;
    padding: 12px 14px;
    font-size: 16px;
    color: rgba(19, 47, 81, 0.75);
    transition: background-color 0.4s cubic-bezier(0.37, 0, 0.63, 1);
    &:focus {
        outline: 0;
        background-color: rgba(255, 255, 255, 0.81);
    }
    &.is-invalid {
        background-position: calc(100% - 16px) 50%;
        background-repeat: no-repeat;
        background-size: auto 60%;
        background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-circle' class='svg-inline--fa fa-exclamation-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ec3d3d' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3E%3C/path%3E%3C/svg%3E");
    }
}

h3 {
    margin-top: 4px;
    text-align: center;
    line-height: 1.4;
    font-weight: normal;
    font-size: 16px;
    @media (min-width: 1024px) {
        font-size: 20px;
    }
}

.btn {
    width: 237px;
    background: #274173;
    margin: 38px auto;
}

.checkbox {
    margin-top: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        &[checked] {
            background: #000;
        }
    }
    .input-container {
        position: relative;
        display: block;
        width: 25px;
        height: 25px;
        margin: 0 1em 0 0;
        border-radius: 5px;
        background-color: #c9e1f1;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 70%;
            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23274173' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");

            opacity: 0;
            transform: scale(0.8);
            transition: all 0.15s cubic-bezier(0.37, 0, 0.63, 1);
        }
    }

    &.is-checked .input-container::before {
        opacity: 1;
        transform: scale(1);
    }
}
</style>

<style lang="scss">
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #274173;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #274173 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.contact__title__phone {
    font-size: 0.9em;
    font-weight: 300;
    letter-spacing: 0.05em;
}
</style>
