import Vue from 'vue';
import Vuex from 'vuex';

import content from './modules/content.js'; 

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
      content
    },
});
