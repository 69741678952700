<template>
    <section class="section3">
        <div class="anchor" id="methods"></div>
        <header class="heading bg1">
            <img
                class="bg bg--mobile"
                src="../assets/therapies/mobile/wave-1.svg"
                alt=""
            />
            <img
                class="bg bg--desk"
                src="../assets/therapies/wave-1.svg"
                alt=""
            />
            <div class="wrapper">
                <h2>
                    {{ content.section3.methods.title[lang] }}
                </h2>
                <div v-html="content.section3.methods.copy[lang]"></div>
            </div>
        </header>
        <div class="accordion-container">
            <div class="accordion bg2" :class="{ open: open2 }">
                <div class="heading">
                    <img
                        class="bg bg--mobile"
                        src="../assets/therapies/mobile/wave-2.svg"
                        alt=""
                    />
                    <img
                        class="bg bg--desk"
                        src="../assets/therapies/wave-2.svg"
                        alt=""
                    />

                    <div class="wrapper">
                        <h3 v-on:click="open2 = !open2">
                            {{ content.section3.briefTherapy.title[lang] }}
                        </h3>
                    </div>
                </div>

                <collapse-transition>
                    <div class="content" v-if="open2">
                        <div
                            class="wrapper"
                            v-html="content.section3.briefTherapy.copy[lang]"
                        ></div>
                    </div>
                </collapse-transition>
            </div>
            <div class="accordion bg3" :class="{ open: open3 }">
                <div class="heading">
                    <img
                        class="bg bg--mobile"
                        src="../assets/therapies/mobile/wave-3.svg"
                        alt=""
                    />
                    <img
                        class="bg bg--desk"
                        src="../assets/therapies/wave-3.svg"
                        alt=""
                    />
                    <div class="wrapper">
                        <h3 v-on:click="open3 = !open3">
                            {{ content.section3.hypnotherapy.title[lang] }}
                        </h3>
                    </div>
                </div>
                <collapse-transition>
                    <div class="content" v-if="open3">
                        <div
                            class="wrapper"
                            v-html="content.section3.hypnotherapy.copy[lang]"
                        ></div>
                    </div>
                </collapse-transition>
            </div>
            <div class="accordion bg4" :class="{ open: open4 }">
                <div class="heading">
                    <img
                        class="bg bg--mobile"
                        src="../assets/therapies/mobile/wave-4.svg"
                        alt=""
                    />
                    <img
                        class="bg bg--desk"
                        src="../assets/therapies/wave-4.svg"
                        alt=""
                    />
                    <div class="wrapper">
                        <h3 v-on:click="open4 = !open4">
                            {{ content.section3.stressManagement.title[lang] }}
                        </h3>
                    </div>
                </div>
                <collapse-transition>
                    <div class="content" v-if="open4">
                        <div
                            class="wrapper"
                            v-html="
                                content.section3.stressManagement.copy[lang]
                            "
                        ></div>
                    </div>
                </collapse-transition>
            </div>
            <div class="accordion bg5" :class="{ open: open5 }">
                <div class="heading ">
                    <img
                        class="bg bg--mobile"
                        src="../assets/therapies/mobile/wave-5.svg"
                        alt=""
                    />
                    <img
                        class="bg bg--desk"
                        src="../assets/therapies/wave-5.svg"
                        alt=""
                    />
                    <div class="wrapper">
                        <h3 v-on:click="open5 = !open5">
                            {{ content.section3.imoEmdr.title[lang] }}
                        </h3>
                    </div>
                </div>
                <collapse-transition>
                    <div class="content content--coaching" v-if="open5">
                        <div
                            class="wrapper"
                            v-html="content.section3.imoEmdr.copy[lang]"
                        ></div>
                        <img
                            src="../assets/COACHING_bg.svg"
                            class="coaching-decor"
                            alt=""
                        />
                    </div>
                </collapse-transition>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
export default {
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    data() {
        return {
            open2: false,
            open3: false,
            open4: false,
            open5: false,
        };
    },
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
    components: {
        CollapseTransition,
    },
};
</script>

<style lang="scss" scoped>
section {
    p {
        margin-top: 0.75em;
        a {
            text-decoration: underline;
        }
    }
}

.heading {
    text-align: center;

    h2,
    h3 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

        @media (min-width: 600px) {
            font-size: 22px;
        }
    }
    &.bg1 h2 {
        font-size: 30px;
        font-weight: 600;
    }
}

.accordion {
    .heading h3::after {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        margin: 4px auto;
        transition: transform 0.2s;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8L8.07107 15.0711L15.1421 8' stroke='white' stroke-opacity='0.6' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
    &.open {
        h3 {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }
}

.heading {
    position: relative;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    overflow: hidden;
    width: 100%;
    z-index: 3;

    .bg {
        width: 100%;
        max-width: none;
        width: 100%;
        @media (min-width: 768px) {
            width: 1920px;
        }
        @media (min-width: 768px) and (max-width: 1920px) {
            margin-left: calc(((1920px - 100vw) / 2) * -1);
        }

        &.bg--desk {
            display: none;
        }
        @media (min-width: 768px) {
            &.bg--desk {
                display: block;
            }
            &.bg--mobile {
                display: none;
            }
        }
    }
}

.heading .wrapper {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 100%;
    z-index: 5;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
p {
    margin-top: 1em;
}

.content {
    text-align: center;
    max-width: 50em;
    margin: auto;

    .wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        min-height: 220px;
        padding-bottom: 3em;
        @media (min-width: 768px) {
            min-height: 420px;

            padding-bottom: 5em;
        }
    }
}

.bg1 {
    img {
        width: auto;
    }
    .wrapper {
        padding-top: 20%;
        @media (min-width: 768px) {
            padding-top: 60px;
        }
    }
}

.bg2 {
    margin-top: -23%;
    .content {
        padding-top: 0;
        padding-bottom: 26%;
        @media (min-width: 768px) {
            padding-bottom: 0;
        }
    }

    @media (min-width: 768px) {
        margin-top: -50px;
    }
}
.bg3 {
    margin-top: -20%;

    .content {
        padding-bottom: 20%;
        @media (min-width: 768px) {
            padding-bottom: 40px;
        }
    }
    @media (min-width: 768px) {
        margin-top: -60px;
    }
}
.bg4 {
    margin-top: -15%;

    .heading .wrapper {
        justify-content: flex-start;
        padding-top: 14%;
        @media (min-width: 768px) {
            padding-top: 40px;
        }
    }
    .content {
        padding-bottom: 40%;
        @media (min-width: 768px) {
            padding-bottom: 70px;
        }
    }
    @media (min-width: 768px) {
        margin-top: -40px;
    }
}
.bg5 {
    margin-top: -40%;
    .content {
        .wrapper {
            min-height: 320px;
            margin-bottom: 4%;
        }
        @media (min-width: 768px) {
            padding-bottom: 0;
        }
        .wrapper {
            padding-bottom: 0;
        }
    }
    @media (min-width: 768px) {
        margin-top: -80px;
    }
}

.coaching-decor {
    position: absolute;
    top: 100%;
    left: 0;
}

.heading h3 {
    cursor: pointer;
    width: 100%;
}
</style>

<style lang="scss">
.section3 {
    .bg1 p {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    p {
        margin-top: 0.75em;
        font-size: 14px;
        text-align: justify;
        line-height: 1.6;
        a {
            text-decoration: underline;
        }
        @media (min-width: 768px) {
            font-size: 16px;
        }
    }
    .content p:last-child {
        //padding-bottom: 3em;
    }
    .accordion-container {
        .accordion {
            position: relative;
            &.open {
                background: #f4f8fc;
            }
        }
    }
}
</style>
