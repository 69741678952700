<template>
    <section class="presentation" id="section2">
        <div
            class="wrapper"
            data-aos="fade"
            data-aos-anchor-placement="top-bottom"
        >
            <h2>May ledan</h2>
            <h3>{{ content.section2.subtitle[lang] }}</h3>
            <div class="copy" v-html="content.section2.copy[lang]"></div>
            <div class="contact">
                <p class="contact__title">
                    {{ content.section2.cta.title[lang] }}
                </p>
                <p class="contact__btn">
                    <a
                        v-on:click.prevent="scrollToAnchor('contact')"
                        class="btn"
                    >
                        {{ content.section2.cta.buttonLabel[lang] }}
                    </a>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
    methods: {
        scrollToAnchor(hash) {
            this.$router.push({ hash: '#' + hash });
            /* if (hash !== window.location.hash.split('#')[1]) {
                this.$router.push({ hash: '#' + hash });
            } */
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    padding-top: 60px;
    color: #37587d;
    text-align: center;
    line-height: 1.6;
    background: #fff;
}
h2 {
    font-size: 31px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.21em;
    line-height: 1;
    @media (min-width: 768px) {
        font-size: 37px;
    }
}
h3 {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    @media (min-width: 768px) {
        font-size: 22px;
    }
}

.contact {
    margin: 24px auto;
    .btn {
        display: inline-block;
    }
    @media (max-width: 767px) {
        display: flex;
        flex-flow: column wrap;
        .contact__title {
            order: 0;
        }
        .contact__data {
            order: 1;
        }
        .contact__btn {
            order: 2;
        }
    }
}
.contact__data {
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1.5;
    margin: 8px auto;
    @media (min-width: 768px) {
        margin-top: 24px;
    }
}
.contact__phone {
    font-size: 28px;
}
.contact__email {
    font-size: 20px;
    span {
        font-weight: 400;
    }
}
.copy {
    margin-top: 1.5em;
}
</style>

<style lang="scss">
#section2 {
    .copy {
        strong {
            font-weight: 500;
        }
    }
}
</style>
