<template>
    <div>
        <Header :lang="lang" />
        <Section1 :lang="lang" />
        <Section2 :lang="lang" />
        <Section3 :lang="lang" />
        <Section4 :lang="lang" />
        <Section5 :lang="lang" />
        <Section6 :lang="lang" />
        <Section7 :lang="lang" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./components/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";

export default {
    metaInfo: {
        htmlAttrs: {
            lang: "en",
        },
        link: [{ rel: "icon", href: "./img/favicon.png" }],
        title: "May Ledan",
        meta: [
            {
                name: "og:title",
                content: "May Ledan",
            },
            {
                name: "og:type",
                content: "website",
            },
            {
                name: "og:image",
                content: "assets/intro__bg.png",
            },
            {
                name: "og:url",
                content: "http://mayledan.com",
            },
        ],
    },
    props: {
        lang: {
            type: String,
            default: "en",
        },
    },
    components: {
        Header,
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6,
        Section7,
    },
    data() {
        return {
            currentLanguage: "fr",
        };
    },
    computed: {
        ...mapGetters({
            content: "GET_CONTENT",
        }),
    },
    created() {},
};
</script>

<style lang="scss"></style>
