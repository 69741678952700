<template>
    <section class="section4" id="aboutSec">
        <div class="anchor" id="about"></div>

        <div class="wrapper">
            <h2 class="title" data-aos="fade">
                {{ content.section4.title[lang] }}
            </h2>
            <div class="flex">
                <div class="content">
                    <div class="pic" data-aos="fade-left">
                        <img
                            src="../assets/mayledan_profile_picture.png"
                            alt="May Ledan"
                        />
                    </div>
                    <div
                        class="copy copy--1"
                        data-aos="fade-up"
                        v-html="content.section4.copy[lang]"
                    ></div>
                    <div
                        class="copy copy--2 tac italic"
                        v-html="content.section4.copy2[lang]"
                    ></div>
                </div>
            </div>
        </div>

        <div class="banner">
            <img
                src="../assets/quote_bg.png"
                class="banner__bg banner__bg--mobile"
                alt=""
            />
            <img
                src="../assets/quote_bg--desktop.svg"
                class="banner__bg banner__bg--desktop"
                alt=""
            />
            <div class="banner__heading wrapper">
                <p class="banner__quote">
                    {{ content.section4.banner.quote[lang] }}
                </p>
                <span class="quote-credit" data-aos="fade-right">
                    {{ content.section4.banner.credit[lang] }}
                </span>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
};
</script>

<style lang="scss" scoped>
section {
    padding: 80px 0 0;
    overflow: hidden;
}
.flex {
    display: flex;
    flex-flow: column wrap;
}
.title {
    @media (min-width: 768px) {
        text-align: left;
    }
}
.content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .pic {
        order: 0;
    }
    .copy--2 {
        order: 1;
    }
    .copy--1 {
        order: 2;
    }
    @media (min-width: 768px) {
        margin-top: 32px;
        padding: 0 0 36px 0;

        .copy--1 {
            flex: 1 1 0;
            order: 0;
        }
        .pic {
            flex-shrink: 0;
            order: 1;
            margin-left: 20px;
        }
        .copy--2 {
            width: 100%;
            flex: 1 1 100%;
            order: 2;
            padding-top: 32px;
        }
    }
}
.pic {
    margin: 16px auto;
    width: 237px;
    /*  width: 237px;
    height: 264px;
    
    background: #eee;
    border-radius: 50%;
    flex-shrink: 0; */

    overflow: hidden;
    img {
        object-fit: cover;
    }

    @media (min-width: 768px) {
        width: auto;
        flex-basis: 35%;
    }

    @media (min-width: 1024px) {
        width: 362px;
        height: 404px;
        margin-left: 40px;
    }
}

.banner {
    position: relative;
    overflow: hidden;
    width: 100%;
}
.banner__bg {
    width: 100%;
    &.banner__bg--desktop {
        display: none;
    }
    @media (min-width: 700px) {
        &.banner__bg--mobile {
            display: none;
        }
        &.banner__bg--desktop {
            display: block;
            width: 1920px;
            max-width: none;

            @media (max-width: 1920px) {
                margin-left: calc(((1920px - 100vw) / 2) * -1);
            }
        }
    }
}

.banner__heading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-weight: 300;
    font-style: italic;
    font-size: 22px;
    max-width: 26em;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    @media (min-width: 375px) {
        padding: 0 1em;
    }

    @media (min-width: 700px) {
        padding: 0;
    }
    @media (min-width: 1024px) {
        font-size: 26px;
    }
}
</style>

<style lang="scss">
.section4 {
    .banner__heading {
        .quote-credit {
            display: block;
            font-style: italic;
            font-weight: 300;
            font-size: 20px;
            text-align: right;
            width: 100%;
            margin-top: 30px;
            @media (min-width: 375px) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            @media (min-width: 700px) {
                margin-top: 0px;
            }
            @media (min-width: 1024px) {
                margin-right: -150px;
            }
        }
    }
    .copy--2 {
        strong {
            font-weight: 500;
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .banner__quote {
        margin-top: -60px;
    }
}
</style>
