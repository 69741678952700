<template>
    <section>
        <div class="wrapper">
            <h2 class="title" data-aos="fade">
                {{ content.section5.title[lang] }}
            </h2>
            <h3 class="subtitle" data-aos="fade">
                {{ content.section5.subtitle[lang] }}
            </h3>

            <ul>
                <li data-aos="fade-up" data-aos-delay="0">
                    <h3>{{ content.section5.box1.title[lang] }}</h3>
                    <p>
                        {{ content.section5.box1.copy[lang] }}
                    </p>
                </li>
                <li data-aos="fade-up" data-aos-delay="300">
                    <h3>{{ content.section5.box2.title[lang] }}</h3>
                    <p>
                        {{ content.section5.box2.copy[lang] }}
                    </p>
                </li>
                <li data-aos="fade-up" data-aos-delay="600">
                    <h3>{{ content.section5.box3.title[lang] }}</h3>
                    <p>
                        {{ content.section5.box3.copy[lang] }}
                    </p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        lang: {
            type: String,
            default: 'en',
        },
    },
    computed: {
        ...mapGetters({
            content: 'GET_CONTENT',
        }),
    },
};
</script>

<style lang="scss" scoped>
section {
    padding: 75px 0;
}
.wrapper {
    max-width: 1192px;
}
ul {
    margin: 36px auto;
    @media (min-width: 768px) {
        margin: 42px auto 36px auto;
    }
    li {
        width: 230px;
        height: 230px;
        padding: 10px 20px;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #294474;
        color: #fff;
        font-weight: 300;
        border-radius: 50%;
        font-size: 14px;
        margin: 60px auto;
        h3 {
            text-transform: uppercase;
        }
        p {
            margin-top: 0.5em;
        }
        @media (min-width: 1024px) {
            width: 241px;
            height: 241px;
        }
    }
    @media (min-width: 768px) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        li {
            margin: 0;
        }
    }
}
</style>
